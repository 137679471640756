<template>
  <AccountSharedLayout class="AccountVoucherView">
    <span slot="title">{{ $t('title') }}</span>

    <span slot="subtitle">{{ $t('subtitle') }}</span>

    <template slot="main">
      <form
        name="form"
        @submit.prevent="subscribe()">
        <FormGroup
          v-model="code"
          :label="$t('couponFieldLabel')"
          :v="$v.code"
          :debounce="500" />

        <div class="centeringHelper">
          <SubmitButton
            class="submitButton"
            :label="$t('submitButton')"
            :working="working"
            :disabled="$v.$invalid" />
        </div>
      </form>

      <div class="centeringHelper">
        <div
          class="secondaryButton --global-clickable"
          @click="skip()">
          {{ $t('skipButton') }}
        </div>
      </div>
    </template>
  </AccountSharedLayout>
</template>

<translations>
  title: 'Enter your coupon code'
  title_no: 'Tast inn kupongkoden din'
  subtitle: 'Use the coupon code if you have a partner, company or family membership.'
  subtitle_no: 'Bruk kupongen dersom du har partner-, bedrift- eller familieavtale.'
  couponFieldLabel: 'Coupon code'
  couponFieldLabel_no: 'Kupongkode'
  submitButton: 'Use Coupon'
  submitButton_no: 'Bruk kupong'
  skipButton: 'Skip'
  skipButton_no: 'Hopp over'
</translations>

<script>
import { required } from 'vuelidate/lib/validators';
import { getCoupon } from '@/account-utils';
import AccountSharedLayout from '../components/AccountSharedLayout';
import FormGroup from '../components/FormGroup';
import SubmitButton from '../components/SubmitButton';
import cbPlans from './AccountCheckoutView/plans';

export default {
    components: { AccountSharedLayout, FormGroup, SubmitButton },
    data() {
        return {
            code: '',
            working: false
        };
    },
    computed: {},
    created() {},
    methods: {
        async subscribe() {
            if (this.$v.$invalid) {
                return;
            }
            this.working = true;
            try {
                const currency = this.$store.state.moduleAuth.profile.currency;
                const plans = cbPlans.filter(p => {
                    return p.currency === currency && p.planType === 'month';
                });
                const plan = plans[0];
                await this.$store.dispatch('moduleAuth/subscribe', {
                    subscription: { plan_id: plan.id, trial: true },
                    customer: {},
                    coupon: this.code
                });
            } catch (err) {
                //   if (responseJson.type === 'payment') {
                //     this.paymentError = true
                //   } else {
                //     this.backendError = responseJson.error
                //   }
                return;
            } finally {
                this.working = false;
            }
            // window.ga('send', 'event', 'Registration', 'Subscribed', 'month')
            return this.$router.push({ name: 'DashboardHomeView' });
        },
        skip() {
            this.$router.push({ name: 'DashboardHomeView' });
        }
    },
    validations: {
        code: {
            required,
            async voucher(value) {
                if (value === '') return true;
                try {
                    await getCoupon(this.code);
                } catch (err) {
                    return false;
                }
                return true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.AccountVoucherView {
    // padding: 1em;
}
.centeringHelper {
    text-align: center;
}
.submitButton {
    width: 100%;
    margin-top: 2em;
}
.secondaryButton {
    padding: 1em 2em;
    display: inline-block;
    color: $greenButtonBackground;
}
</style>
